import React, { useState, useContext, useEffect } from 'react'
import { Container, Box, Avatar, Typography, Grid, TextField, Button, CircularProgress } from '@mui/material'
import { LockOutlined } from "@mui/icons-material";
import { ApiError, CapitalComAdminService, SessionKeyCreate } from '../../api';
import AuthContext from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { CAPITAL_HOST_DEMO_ALERT, CAPITAL_HOST_DEMO_SERVER } from '../../utils/constants';

const UpdateKeys = () => {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hostServer, setHostServer] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(".")
  const [error, setError]= useState("")

  const { logoutUser } = useContext(AuthContext)

  useEffect(() => {
    setLoading(true)

    CapitalComAdminService.readSessionKeyForMeCapitalComAdminMeSessionkeyGet()
    .then(sessionKey => {
      setTitle(sessionKey.title!)
      setDescription(sessionKey.description!)
      setHostServer(sessionKey.host_server!)
      setApiKey(sessionKey.api_key!)
    })
    .catch((error:ApiError) => {
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false);
    })
  } , [])

  const navigate = useNavigate();

  const delay = async (ms: number) => {
    return new Promise((resolve) => 
      setTimeout(resolve, ms));
  };

  const handleUpdate = () => {
    setLoading(true)
    setStatus("Updating...")
    setError("")

    const data: SessionKeyCreate = {
      title,
      description,
      host_server: hostServer,
      api_key: apiKey,
      password,
    }

    CapitalComAdminService.updateSessionKeysForMeCapitalComAdminMeSessionkeyPut(
      data
    )
    .then(sessionKey => {
      console.log(sessionKey)
      setStatus("you should login again!")
      delay(3000).then(() => {
        logoutUser()
      })
    })
    .catch((error:ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false);
    })
  }

  const handleDelete = () => {
    setError("")
    setError("delete not implemented yet!")
  }

  const handleCancel = () => {
    setError("")
    setStatus("Canceling...")
    navigate("/")
  }

  const showHostServerHelperText = (): boolean => {
    return hostServer !== CAPITAL_HOST_DEMO_SERVER
  }

  const getHostServerHelperText = (): string => {
    return showHostServerHelperText()? CAPITAL_HOST_DEMO_ALERT : ""
  }

  return (
    <>
      <Container maxWidth="xs">
        <Box 
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
            <LockOutlined />
          </Avatar>
          <Typography variant="h5">Update Keys @capital.com</Typography>

          {loading && <CircularProgress />}

          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  name="title"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  autoFocus
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="hostServer"
                  label="Host Server"
                  name="hostServer"
                  value={hostServer}
                  onChange={(e) => setHostServer(e.target.value)}
                  error={showHostServerHelperText()}
                  helperText={getHostServerHelperText()}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="apiKey"
                  required
                  fullWidth
                  id="apiKey"
                  label="API Key"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>

            </Grid>
          </Box>

          {status && <Typography color="green">{status}</Typography>}
          {error && <Typography color="red">{error}</Typography>}

          <Grid 
            item
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleUpdate}
              disabled={loading}
            >
              Update
            </Button>

            <Button
              sx={{ mt: 3, mb: 2 }}
              onClick={handleDelete}
              disabled={loading}
            >
              Delete
            </Button>

            <Button
              sx={{ mt: 3, mb: 2 }}
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>

          </Grid>

        </Box>
      </Container>  
    </>
  )
}

export default UpdateKeys
