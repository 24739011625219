import React, { useState, useEffect } from 'react'
import { ApiError, StratScale, StratScaleUpdate, StrategyService } from '../../api';
import { Box, Button, CircularProgress, Container, Typography } from '@mui/material';
import StratDataGrid from '../../components/StratDataGrid';
import { GridRowId, GridRowModel } from '@mui/x-data-grid';


const Settings = () => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(".")
  const [error, setError] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [data, setData] = useState<StratScale[]>([])

  useEffect(() => {
    handleSubmitRefresh()
  } , [] )

  const handleSubmitRefresh = () => {
    setLoading(true)
    setError("")
    setDisabled(true)
    
    StrategyService.readUserStratScalesTemplateApiStrategyScalesMeTemplateGet()
    .then((response: StratScale[]) => {
      if (response.length >= 1) {
        setData(response)
      }
    })
    .catch((error: ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false)
      setDisabled(false)
    })
  }

  const handleSubmitNewScale = (newScale: StratScaleUpdate) => {
    setLoading(true)
    setError("")
    setDisabled(true)

    StrategyService.createUserStratScaleTemplateApiStrategyScaleMeTemplatePost(newScale)
    .then((response: StratScale) => {
    })
    .catch((error: ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false)
      setDisabled(false)
    })
  }

  const handleSubmitUpdateScale = (updateScale: StratScaleUpdate) => {
    setLoading(true)
    setError("")
    setDisabled(true)
    
    StrategyService.updateUserStratScaleTemplateApiStrategyScaleMeTemplatePatch(updateScale)
    .then((response: StratScale) => {
    })
    .catch((error: ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false)
      setDisabled(false)
    })
  }

  const handleDeleteScale = (id: GridRowId) => {
    setLoading(true)
    setError("")
    setDisabled(true)
    
    StrategyService.deleteUserStratScaleApiStrategyScaleMeTemplateIdDelete(Number(id))
    .then((response: StratScale) => {
    })
    .catch((error: ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false)
      setDisabled(false)
    })
  }

  const handleUpdateScale = (newRow: GridRowModel) => {
    const updtateScale: StratScaleUpdate = {
      id: newRow.id,
      price: newRow.price,
      size: newRow.size,
      owner_id: 0
    }

    if (newRow.isNew) {
      handleSubmitNewScale(updtateScale)
    } else {
      handleSubmitUpdateScale(updtateScale)
    }

  }

  return (
    <Container>
      <Box
        sx={{
            mt: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}
      >
        <Typography variant="h5" color={"gray"}>Strategy Template</Typography>

        <StratDataGrid
          data={data}
          updateRow={handleUpdateScale}
          deleteRow={handleDeleteScale}
        />

        {loading && <CircularProgress />}

        {status && <Typography color="green">{status}</Typography>}
        {error && <Typography color="red">{error}</Typography>}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmitRefresh}
          disabled={loading || disabled}
        >
          Refresh
        </Button>

      </Box>
    </Container>
  )
}

export default Settings
