/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { app__strategy__schemas__task__Task } from '../models/app__strategy__schemas__task__Task';
import type { app__strategy__schemas__task__TaskCreate } from '../models/app__strategy__schemas__task__TaskCreate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StrategyTaskService {
    /**
     * Read User Task Jobs
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readUserTaskJobsApiStrategyTaskMeJobsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/task/me/jobs',
        });
    }
    /**
     * Start User Task
     * @returns any Successful Response
     * @throws ApiError
     */
    public static startUserTaskApiStrategyTaskMeStartPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/strategy/task/me/start',
        });
    }
    /**
     * Stop User Task
     * @returns any Successful Response
     * @throws ApiError
     */
    public static stopUserTaskApiStrategyTaskMeStopPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/strategy/task/me/stop',
        });
    }
    /**
     * Read User Tasks
     * @returns app__strategy__schemas__task__Task Successful Response
     * @throws ApiError
     */
    public static readUserTasksApiStrategyTaskMeTasksMeGet(): CancelablePromise<Array<app__strategy__schemas__task__Task>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/task/me/tasks/me',
        });
    }
    /**
     * Update User Task
     * @param requestBody
     * @returns app__strategy__schemas__task__Task Successful Response
     * @throws ApiError
     */
    public static updateUserTaskApiStrategyTaskMeTasksMePut(
        requestBody: app__strategy__schemas__task__TaskCreate,
    ): CancelablePromise<app__strategy__schemas__task__Task> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/strategy/task/me/tasks/me/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create User Task
     * @param requestBody
     * @returns app__strategy__schemas__task__Task Successful Response
     * @throws ApiError
     */
    public static createUserTaskApiStrategyTaskMeTasksMePost(
        requestBody: app__strategy__schemas__task__TaskCreate,
    ): CancelablePromise<app__strategy__schemas__task__Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/strategy/task/me/tasks/me/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Task
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUserTaskApiStrategyTaskMeTasksMeDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/strategy/task/me/tasks/me/',
        });
    }
}
