/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Config } from '../models/Config';
import type { ConfigCreate } from '../models/ConfigCreate';
import type { ConfigUpdate } from '../models/ConfigUpdate';
import type { StratScale } from '../models/StratScale';
import type { StratScaleCreate } from '../models/StratScaleCreate';
import type { StratScaleUpdate } from '../models/StratScaleUpdate';
import type { StratShare } from '../models/StratShare';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StrategyService {
    /**
     * Read User Config
     * @returns Config Successful Response
     * @throws ApiError
     */
    public static readUserConfigApiStrategyConfigMeGet(): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/config/me/',
        });
    }
    /**
     * Update User Config
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUserConfigApiStrategyConfigMePut(
        requestBody: ConfigUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/strategy/config/me/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User Config
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUserConfigApiStrategyConfigMeDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/strategy/config/me/',
        });
    }
    /**
     * Create User Config
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createUserConfigApiStrategyCongigMePost(
        requestBody: ConfigCreate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/strategy/congig/me/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read User Strat Scales
     * @returns StratScale Successful Response
     * @throws ApiError
     */
    public static readUserStratScalesApiStrategyScalesMeGet(): CancelablePromise<Array<StratScale>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/scales/me/',
        });
    }
    /**
     * Read User Strat Scales Template
     * @returns StratScale Successful Response
     * @throws ApiError
     */
    public static readUserStratScalesTemplateApiStrategyScalesMeTemplateGet(): CancelablePromise<Array<StratScale>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/scales/me/template/',
        });
    }
    /**
     * Delete User Strat Scales
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUserStratScalesApiStrategyScalesMeTemplateDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/strategy/scales/me/template/',
        });
    }
    /**
     * Create User Strat Scales Template Strat 01
     * @returns StratScale Successful Response
     * @throws ApiError
     */
    public static createUserStratScalesTemplateStrat01ApiStrategyScalesMeTemplateStrat01Post(): CancelablePromise<Array<StratScale>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/strategy/scales/me/template/strat_01',
        });
    }
    /**
     * Create User Strat Scale Template
     * @param requestBody
     * @returns StratScale Successful Response
     * @throws ApiError
     */
    public static createUserStratScaleTemplateApiStrategyScaleMeTemplatePost(
        requestBody: StratScaleCreate,
    ): CancelablePromise<StratScale> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/strategy/scale/me/template/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User Strat Scale Template
     * @param requestBody
     * @returns StratScale Successful Response
     * @throws ApiError
     */
    public static updateUserStratScaleTemplateApiStrategyScaleMeTemplatePatch(
        requestBody: StratScaleUpdate,
    ): CancelablePromise<StratScale> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/strategy/scale/me/template/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Strat Scale
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUserStratScaleApiStrategyScaleMeTemplateIdDelete(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/strategy/scale/me/template/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read User Strat Scales Current
     * @returns StratScale Successful Response
     * @throws ApiError
     */
    public static readUserStratScalesCurrentApiStrategyScalesMeCurrentGet(): CancelablePromise<Array<StratScale>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/scales/me/current/',
        });
    }
    /**
     * Read User Strat Scale
     * @returns StratScale Successful Response
     * @throws ApiError
     */
    public static readUserStratScaleApiStrategyScaleMeGet(): CancelablePromise<StratScale> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/scale/me/',
        });
    }
    /**
     * Delete User Strat Scale
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUserStratScaleApiStrategyScaleMeIdDelete(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/strategy/scale/me/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read User Strat Shares
     * @returns StratShare Successful Response
     * @throws ApiError
     */
    public static readUserStratSharesApiStrategySharesMeGet(): CancelablePromise<Array<StratShare>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/strategy/shares/me/',
        });
    }
}
