import React from 'react'
import { Box, Typography } from '@mui/material';

interface Props {
  contentText: string
}

const Footer = (props: Props) => {
  const { contentText } = props;

  return (
    <Box 
      sx={{ 
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        position: "fixed",
        left: 0, 
        right: 0, 
        bottom: 10,
      }}
    >
      <Typography variant='caption'>
        {contentText}
      </Typography>
    </Box>
  )
}

export default Footer
