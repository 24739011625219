import React, { useState, useContext } from 'react'
import { Box, Container, Typography, Button, Avatar, Grid, TextField, CircularProgress } from '@mui/material'
import { LockOutlined } from "@mui/icons-material";
import { ApiError, SessionKeyCreate, CapitalComAdminService } from '../../api';
import AuthContext from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { CAPITAL_HOST_DEMO_ALERT, CAPITAL_HOST_DEMO_SERVER } from '../../utils/constants';

const RegisterKeys = () => {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hostServer, setHostServer] = useState("demo-api-capital.backend-capital.com");
  const [apiKey, setApiKey] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(".")
  const [error, setError]= useState("")

  const { logoutUser } = useContext(AuthContext)

  const navigate = useNavigate();

  const delay = async (ms: number) => {
    return new Promise((resolve) => 
        setTimeout(resolve, ms));
  };

  const handleRegister = () => {
    setLoading(true)
    setStatus("Registering...")
    setError("")

    const data: SessionKeyCreate = {
      title,
      description,
      host_server: hostServer,
      api_key: apiKey,
      password,
    }

    CapitalComAdminService.createSessionKeysForMeCapitalComAdminMeSessionkeysPost(  
      data
    )
    .then((response) => {
      console.log(response)
      setStatus("you should login again!")
      delay(3000).then(() => {
        logoutUser()
      })
    })
    .catch((error:ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false);
    })

  }

  const handleCancel = () => {
    setError("")
    setStatus("Canceling...")
    navigate("/")
  }

  const showHostServerHelperText = (): boolean => {
    return hostServer !== CAPITAL_HOST_DEMO_SERVER
  }

  const getHostServerHelperText = (): string => {
    return showHostServerHelperText()? CAPITAL_HOST_DEMO_ALERT : ""
  }

  return (
    <>
      <Container maxWidth="xs">
        <Grid 
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
            <LockOutlined />
          </Avatar>
          <Typography variant="h5">Register Keys @capital.com</Typography>

          {loading && <CircularProgress />}

          <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <TextField
                    name="title"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    autoFocus
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="description"
                    label="Description"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="hostServer"
                    label="Host Server"
                    name="hostServer"
                    value={hostServer}
                    onChange={(e) => setHostServer(e.target.value)}
                    error={showHostServerHelperText()}
                    helperText={getHostServerHelperText()}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="apiKey"
                    required
                    fullWidth
                    id="apiKey"
                    label="API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>

              </Grid>
          </Box>

          {status && <Typography color="green">{status}</Typography>}
          {error && <Typography color="red">{error}</Typography>}

          <Grid 
            item
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleRegister}
              disabled={loading}
            >
              Register
            </Button>

            <Button
              sx={{ mt: 3, mb: 2 }}
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>

          </Grid>
        </Grid>
      </Container> 
    </>
  )
}

export default RegisterKeys
