import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import TemporaryDrawer from '../sidebar/sidebar';
import { Anchor } from '../sidebar/sidebar'
import AuthContext from '../../context/AuthContext';
import { Logout as LogoutIcon } from '@mui/icons-material';

interface Props {
  title: string
}

export default function ButtonAppBar(props: Props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { user, logoutUser } = React.useContext(AuthContext);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button href="/">
            <img src="/images/logo.png" alt="BontiBot" height="28" />
          </Button>          
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>

          { user &&
            <>
              <Typography sx={{ display: { xs: 'none', sm: 'block' } }} >
                {user}
              </Typography>
              <Button color="inherit" onClick={logoutUser}>
                <LogoutIcon />
              </Button>
            </>
          }
          
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer("right", true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <TemporaryDrawer state={state} toggleDrawer={toggleDrawer}/>
    </Box>
  );
}
