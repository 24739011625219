import React from 'react'
import { Container, CssBaseline, Box } from '@mui/material'
import StartStop from './StartStop'
 
export const Home = () => {
    return (
        <>
            <Container>
                <CssBaseline />
                <Box
                    sx={{
                        mt: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                <img src="/images/BontiBot.png" alt='BontiBot' width={200} />
                </Box>

                <StartStop />

            </Container>
        </>
    )
}
