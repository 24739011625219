import React, { useContext, useState } from 'react' 
import { useNavigate } from "react-router-dom";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { ApiError, AuthService, Body_login_for_access_token_auth_login_post, Token } from '../api';
import AuthContext from '../context/AuthContext';

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { loginUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogin = () => {
    setError("")
    setLoading(true)

    const formData: Body_login_for_access_token_auth_login_post = {
      username: email,
      password: password
    }
    AuthService.loginForAccessTokenAuthLoginPost(formData)
    .then((response: Token) => {
      // console.log(response)
      loginUser(response)
      navigate("/")
    })
    .catch((error:ApiError) => {
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <img src="/images/BontiBot.png" alt='BontiBot' width={200} />
                    
          {loading && <CircularProgress />}

          <Typography sx={{mt:4}}>
              Please login to use BontiBot
          </Typography>

          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type='email'
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            { error? <Typography color="red">{error}</Typography> : <>&nbsp;</>} 

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
              disabled={loading}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};
