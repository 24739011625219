/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResp } from '../models/ApiResp';
import type { CreatePosition } from '../models/CreatePosition';
import type { EpicName } from '../models/EpicName';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CapitalComApiService {
    /**
     * Get Server Time
     * Test connectivity to the API and get the current server time
     * @returns ApiResp Successful Response
     * @throws ApiError
     */
    public static getServerTimeCapitalComApiTimeGet(): CancelablePromise<ApiResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/capital_com/api/time',
        });
    }
    /**
     * Get Ping
     * Ping the service to keep a trading session alive
     * @returns ApiResp Successful Response
     * @throws ApiError
     */
    public static getPingCapitalComApiPingGet(): CancelablePromise<ApiResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/capital_com/api/ping',
        });
    }
    /**
     * Get Session Encryptionkey
     * **Encryption key**
     * Get the encryption key to use in order to send the user password in an encrypted form
     * @returns ApiResp Successful Response
     * @throws ApiError
     */
    public static getSessionEncryptionKeyCapitalComApiSessionEncryptionKeyGet(): CancelablePromise<ApiResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/capital_com/api/session/encryptionKey',
        });
    }
    /**
     * Get Session
     * **Session details**
     * Returns the user's session details
     * @returns ApiResp Successful Response
     * @throws ApiError
     */
    public static getSessionCapitalComApiSessionGet(): CancelablePromise<ApiResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/capital_com/api/session',
        });
    }
    /**
     * Post Session
     * **Create new session**
     * Create a trading session, obtaining session tokens for subsequent API access
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postSessionCapitalComApiSessionPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/capital_com/api/session',
        });
    }
    /**
     * Get Positions
     * All positions
     *
     * Returns all open positions for the active account
     * @returns ApiResp Successful Response
     * @throws ApiError
     */
    public static getPositionsCapitalComApiPositionsGet(): CancelablePromise<ApiResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/capital_com/api/positions',
        });
    }
    /**
     * Post Positions
     * Create position
     *
     * Create orders and positions
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postPositionsCapitalComApiPositionsPost(
        requestBody: CreatePosition,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/capital_com/api/positions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Markets
     * **Single market details**
     * Returns the details of the given market
     * @param epic
     * @returns ApiResp Successful Response
     * @throws ApiError
     */
    public static getMarketsCapitalComApiMarketsEpicGet(
        epic: EpicName,
    ): CancelablePromise<ApiResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/capital_com/api/markets/{epic}',
            path: {
                'epic': epic,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Prices
     * Markets Info > Prices
     * @param epic
     * @returns ApiResp Successful Response
     * @throws ApiError
     */
    public static getPricesCapitalComApiPricesEpicGet(
        epic: EpicName,
    ): CancelablePromise<ApiResp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/capital_com/api/prices/{epic}',
            path: {
                'epic': epic,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
