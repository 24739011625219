import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import ButtonAppBar from './components/navbar/navbar';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings'
import TestPage from './pages/TestPage';
import { AuthProvider } from './context/AuthContext';
import RegisterKeys from './capital_com/Register';
import UpdateKeys from './capital_com/Register/UpdateKeys';
import NotFoundPage from './pages/NotFoundPage';
import AdminOutlet from './components/AdminOutlet';
import PrivateOutlet from './components/PrivateOutlet';
import { CssBaseline } from '@mui/material';
import { APP_TITLE, APP_FOOTER_TEXT } from './utils/constants';
import Footer from './components/footer';


function App() {

  return (
    <AuthProvider>
      <Router>
        <CssBaseline />
        <ButtonAppBar title={APP_TITLE} />
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/admin' element={<AdminOutlet />}>
            <Route path='register' element={<Register />} />
          </Route>
          <Route path="/" element={<PrivateOutlet />}>
            <Route path="" element={<Home />} />
            <Route path='/settings' element={<Settings />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
          <Route path="/capitalcom" element={<PrivateOutlet />}>
            <Route path='register' element={<RegisterKeys />} />
            <Route path='updatekeys' element={<UpdateKeys />} />
            <Route path='test' element={<TestPage />} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Router>
      <Footer contentText={APP_FOOTER_TEXT} />
    </AuthProvider>
  );
}

export default App;
