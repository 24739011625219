import React, {useState, useContext, useEffect} from 'react'
import AuthContext from '../../context/AuthContext'
import { ApiError, app__strategy__schemas__task__Task, StrategyTaskService } from '../../api'
import { Box, Button, FormControl, Typography, TextField, Grid, CircularProgress } from '@mui/material'

const taskInit: app__strategy__schemas__task__Task = {
  id: 0,
  created_at: "",
  updated_at: "",
  title: "dummy",
  owner_id: 0,
  is_active: false,
  cycle_enabled: false,
  cycle_count: 0,
  shares: [],
}

const StartStop = () => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(".")
  const [error, setError] = useState("")

  const [disabled, setDisabled] = useState(false)
  const [task, setTask] = useState<app__strategy__schemas__task__Task>(taskInit)

  const { demoMode } = useContext(AuthContext)

  useEffect(() => {
    handleSubmitRefresh()
  } , [] )

  const formatDuration = (seconds: number) => {
    const days        = Math.floor(seconds / (24*3600))
    let   secondsLeft = seconds % (24*3600);

    const hours       = Math.floor(secondsLeft / 3600);
    const minutes     = Math.floor((secondsLeft % 3600) / 60);
    secondsLeft = secondsLeft % 60;
  
    const formattedHours   = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = secondsLeft.toString().padStart(2, '0');
  
    return `${days} day(s) ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;  
  }
 
  const delay = async (ms: number) => {
    return new Promise((resolve) => 
        setTimeout(resolve, ms));
  };

  const handleSubmitRefresh = () => {
    setLoading(true)
    setStatus("Refreshing...")
    setError("")
    setDisabled(true)
    delay(1000).then(() => {
      setStatus(".")
      setDisabled(false)
    })

    StrategyTaskService.readUserTaskJobsApiStrategyTaskMeJobsGet()
    .then((response:[app__strategy__schemas__task__Task]) => {
      if (response.length >= 1) {
        setTask(response[0])
      }
    })
    .catch((error: ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      // setStatus(".")
      setLoading(false)
    })
  }

  const handleSubmitStart = () => {
    setLoading(true)
    setStatus("Starting...")
    setError("")
    setDisabled(true)
    delay(2000).then(() => {
      setStatus(".")
      setDisabled(false)
    })

    StrategyTaskService.startUserTaskApiStrategyTaskMeStartPost()
    .then((response) => {
      // setStatus(JSON.stringify(response))
      setTask(response)
    })
    .catch((error: ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false)
      // setStatus(".")
    })
  }

  const handleSubmitStop = () => {
    setLoading(true)
    setStatus("Stopping...")
    setError("")
    setDisabled(true)
    delay(2000).then(() => {
      setStatus(".")
      setDisabled(false)
    })

    StrategyTaskService.stopUserTaskApiStrategyTaskMeStopPost()
    .then((response) => {
      // setStatus(JSON.stringify(response))
      setTask(response)
    })
    .catch((error: ApiError) => {
      setStatus(".")
      setError(`⚠️ ${error}`)
    })
    .finally(() => {
      setLoading(false)
      // setStatus(".")
    })
  }


  return (
    <>
      <Box 
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1
        }}
      >

        {demoMode?
          <Typography variant="h5" color={"gray"}>DEMO</Typography>
        :
          <Typography variant="h5" color={"green"}>LIVE</Typography>
        } 

        {loading && <CircularProgress />}

        <Grid
          item
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <TextField
              label="cycle_enabled"
              variant="outlined"
              id="cycle-enabled"
              type="text"
              value = {task.cycle_enabled}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="is_active"
              variant="outlined"
              id="is-active"
              type="text"
              value = {task.is_active}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <TextField
              label="cycle_count"
              variant="outlined"
              id="cycle-count"
              type="number"
              value = {task.cycle_count}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="number of shares"
              variant="outlined"
              id="number-of-shares"
              type="number"
              value = {task.shares? task.shares.length : 0}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid
          item
        >

          <Typography>duration: {formatDuration(task.cycle_count!*task.cycle_interval!)}</Typography>

          {status && <Typography color="green">{status}</Typography>}
          {error && <Typography color="red">{error}</Typography>}

        </Grid>

        <Grid
            item
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
        >

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmitStart}
            disabled={loading || disabled || task.cycle_enabled}
          >
            Start
          </Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmitRefresh}
            disabled={loading || disabled}
          >
            Refresh
          </Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmitStop}
            disabled={loading || disabled || !task.cycle_enabled}
          >
            Stop
          </Button>

        </Grid>

      </Box>
    </>
  )
}

export default StartStop
