import React from 'react'
import { Container, CssBaseline, Box, Typography } from '@mui/material'

const Dashboard = () => {
  return (
    <>
      <Container>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Dashboard
          </Typography>
        </Box>
      </Container>
    </>
  )
}

export default Dashboard
