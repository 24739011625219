import { useState } from 'react'
import { Box, Button, Container, CssBaseline, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { ApiError, CapitalComApiService, EpicName, OpenAPI, Direction } from '../../api'

const TestPage = () => {
  const [service, setService] = useState('');
  const [result, setResult] = useState("")
  const [epic, setEpic] = useState<EpicName>(EpicName.GOLD)
  const [direction, setDirection] = useState<Direction>(Direction.SELL)
  const [size] = useState<number>(0.1)
  const [error, setError] = useState("")

  const handleChange = (event: SelectChangeEvent) => {
    setService(event.target.value as string);
    setResult("")
  };

  const handleEpicChange = (event: SelectChangeEvent) => {
    setEpic(event.target.value as EpicName);
    setResult("")
  };

  const handleDirectionChange = (event: SelectChangeEvent) => {
    setDirection(event.target.value as Direction);
    setResult("")
  };

  const handleSubmit = () => {
    setError("")

    switch (service) {
      case "time": getTime()
        break
      case "ping": getPing()
        break
      case "postSession": postSession()
        break
      case "getMarkets": getMarkets()
        break
      case "getPositions": getPositions()
        break
      case "postPositions": postPositions()
        break
    }
  }

  const getTime = () => {
    CapitalComApiService.getServerTimeCapitalComApiTimeGet()
    .then((response) => {
      setResult(JSON.stringify(response))
    })
    .catch((error: ApiError) => {
      setError(`⚠️ ${error}`)
    })
  }

  const getPing = () => {
    CapitalComApiService.getPingCapitalComApiPingGet()
    .then((response) => {
      setResult(JSON.stringify(response))
    })
    .catch((error: ApiError) => {
      setError(`⚠️ ${error}`)
    })
  }

  const postSession = () => {
    CapitalComApiService.postSessionCapitalComApiSessionPost()
    .then((response) => {
      setResult(JSON.stringify(response))
    })
    .catch((error: ApiError) => {
      setError(`⚠️ ${error}`)
    })
  }

  const getMarkets = () => {
    CapitalComApiService.getMarketsCapitalComApiMarketsEpicGet(epic)
    .then((response) => {
      setResult(JSON.stringify(response))
    })
    .catch((error: ApiError) => {
      setError(`⚠️ ${error}`)
    })
  }

  const getPositions = () => {
    CapitalComApiService.getPositionsCapitalComApiPositionsGet()
    .then((response) => {
      setResult(JSON.stringify(response))
    })
    .catch((error: ApiError) => {
      setError(`⚠️ ${error}`)
    })
  }

  const postPositions = () => {
    const requestBody = {
      epic: epic,
      direction: direction,
      size: size
    }

    CapitalComApiService.postPositionsCapitalComApiPositionsPost(requestBody)
    .then((response) => {
      setResult(JSON.stringify(response))
    })
    .catch((error: ApiError) => {
      setError(`⚠️ ${error}`)
    })
  }

  return (
    <>
      <Container>
        <CssBaseline />
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Typography variant='h5'>Test Page</Typography>

          <Box sx={{ mt: 3 }}>

          <Box 
            sx={{
              mt: 8,
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">service</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value="time">Get Time</MenuItem>
              <MenuItem value="ping">Get Ping</MenuItem>
              <MenuItem value="postSession">Post Session</MenuItem>
              <MenuItem value="getMarkets">Get Markets</MenuItem>
              <MenuItem value="getPositions">Get Positions</MenuItem>
              <MenuItem value="postPositions">Post Positions</MenuItem>
            </Select>
            </FormControl>

            { (service === "getMarkets" || service === "postPositions") && 
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">epic</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={epic}
                  label="Age"
                  onChange={handleEpicChange}
                >
                  <MenuItem value={EpicName.GOLD}>Gold</MenuItem>
                  <MenuItem value={EpicName.SILVER}>Silver</MenuItem>
                </Select>
              </FormControl>
            }
            { service === "postPositions" && 
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">epic</InputLabel>
                <Select
                  labelId="select-direction-label"
                  id="select-direction-label"
                  value={direction}
                  label="Direction"
                  onChange={handleDirectionChange}
                >
                  <MenuItem value={Direction.BUY}>Buy</MenuItem>
                  <MenuItem value={Direction.SELL}>Sell</MenuItem>
                </Select>
              </FormControl>
            }

          </Box>

          { service === "postPositions" && 
            <Typography variant='h6'>Size: {size}</Typography>
          }

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>

          {error && <Typography color="red">{error}</Typography>}

          <Box>
            <Typography variant='h6'>Result:</Typography>
            <Typography color="text.secondary" variant="body2">
              { result }
            </Typography>
          </Box>

          </Box>

        </Box>
      </Container>
    </>
  )
}

export default TestPage
