// APP TEXT
export const APP_TITLE        = 'BontiBot'
export const APP_DESCRIPTION  = 'BontiBot is a web-app for a strategic trading/investment algorithm.'
export const APP_FOOTER_TEXT  = `© ${new Date().getFullYear()} BontiBot (${
  process.env.REACT_APP_VERSION
})`;

// UI CONSTANTS

// URLS
export const CAPITAL_HOST_DEMO_SERVER = "demo-api-capital.backend-capital.com"
export const CAPITAL_HOST_DEMO_ALERT  = "Attention: You are leaving the demo mode at your own risk."
