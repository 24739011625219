import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext';

const AdminOutlet = () => {
  const { isSuperuser } = useContext(AuthContext)

  return isSuperuser ? <Outlet /> : <Navigate to="/login" />;
}

export default AdminOutlet
